<template>
  <div>
    <!-- todo 2 => for html good visual practice divide by blocks (bem methodology https://en.bem.info/) and mark begin and end if block is big -->
    <!-- todo 2 => also divide blocks the same level with one empty line -->
    <!-- Header -->
    <div class="header bg-gradient-info py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center"></div>
        </div>
      </div>

      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          />
        </svg>
      </div>
    </div>
    <!-- Header -->

    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0 text-center">Sign In</h5>
                </div>
              </div>
            </div>

            <div class="card-body px-lg-5 py-lg-5">
              <!-- <div class="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div> -->

              <div v-if="alert.message" :class="'row  mb-2 alert ' + alertClass(alert.type)">{{alert.message}}</div>
              
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(handleLogin)">
                  <base-input
                    alternative
                    class="mb-3"
                    id="username"
                    name="Username"
                    :rules="{ required: true }"
                    prepend-icon="ni ni-single-02"
                    placeholder="Username"
                    v-model="username"
                  >
                  </base-input>
  
                  <base-input
                    alternative
                    class="mb-3"
                    name="Password"
                    id="password"
                    :rules="{ required: true }"
                    prepend-icon="ni ni-lock-circle-open"
                    type="password"
                    placeholder="Password"
                    v-model="password"
                  >
                  </base-input>
                  
                  <div class="text-center">
                    <base-button
                      type="primary"
                      native-type="submit"
                      class="my-4"
                      id="submitbutton"
                    >
                      Sign in
                    </base-button>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 text-center">
              <a
                href="http://test.venuecrew.com/wp-login.php?action=lostpassword"
                class="text-light"
              >
                <small>Forgot password?</small>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      username: '',
      password: '',
      submitted: false,
      redirect: this.$route.query.redirect,
    };
  },
  computed: {
    alert () {
      return this.$store.state.alert;
    },
  },
  created () {
    // reset login status
    this.$store.dispatch('auth/logout');
  },
  methods: {
    handleLogin (e) {
      this.loading = true;
      this.submitted = true;
      const { username, password } = this;
      const { dispatch } = this.$store;
      if (username && password) {
        dispatch('auth/login', { username, password , redirect: this.redirect});
      }
    },
    alertClass(type) {
      if (type === 'error') {
        return 'alert-danger';
      } else if (type === 'success') {
        return 'alert-success';
      }
    },
  },
  beforeDestroy() {
    // clear alert on location change
    this.$store.dispatch("alert/clear");
  },
};
</script>

<style lang="scss">
.page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.alert {
  margin-left: 0;
  margin-right: 0;
}
</style>
